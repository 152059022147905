import React from 'react';
import { TechStackBox } from '@components/TechStackBox/TechStackBox';
import './TechStackSection.css';

export interface TechStackSectionProps {
  width?: string;
  className?: string;
  id?: string;
}

export class TechStackSection extends React.Component<TechStackSectionProps> {
  render(): JSX.Element {
    return (
      <div className={this.props.className} id={this.props.id}>
        <h3 className={'tech-title'}>Most often i work with</h3>
        <ul className={'most-often-grid'}>
          <TechStackBox src={'assets/img/techStack/typescript.svg'} text={'typescript'} alt={"Typescript"}/>
          <TechStackBox src={'assets/img/techStack/react.svg'} text={'react.js'} alt={"React.js"}/>
          <TechStackBox src={'assets/img/techStack/javascript.svg'} text={'javascript'} alt={"Javascript"}/>
          <TechStackBox src={'assets/img/techStack/html-5.svg'} text={'HTML5'} alt={"HTML5"}/>
          <TechStackBox src={'assets/img/techStack/css3.svg'} text={'CSS3'} alt={"CSS3"}/>
          <TechStackBox src={'assets/img/techStack/java.svg'} text={'java'} alt={"Java"}/>
          <TechStackBox src={'assets/img/techStack/kotlin.svg'} text={'kotlin'} alt={"Kotlin"}/>
          <TechStackBox src={'assets/img/techStack/react-native.svg'} text={'react native'} alt={"React Native"}/>
          <TechStackBox src={'assets/img/techStack/git.svg'} text={'git'} alt={"Git"}/>
          <TechStackBox src={'assets/img/techStack/figma.svg'} text={'figma'} alt={"Figma"}/>
          <TechStackBox src={'assets/img/techStack/docker.svg'} text={'docker'} alt={"Docker"}/>
          <TechStackBox src={'assets/img/techStack/golang.svg'} text={'go'} alt={"Go"}/>
        </ul>
        <h3 className="tech-title">Familiar/worked with</h3>
        <ul className={'most-often-grid'}>
          <TechStackBox src={'assets/img/techStack/python.svg'} text={'python'} alt={"Python"}/>
          <TechStackBox src={'assets/img/techStack/php-logo.svg'} text={'php'} alt={"PHP"}/>
          <TechStackBox src={'assets/img/techStack/threejs-logo.svg'} text={'three.js'} alt={"Three.js"}/>
          <TechStackBox src={'assets/img/techStack/spring-boot.svg'} text={'spring boot'} alt={"Spring boot"}/>
          <TechStackBox src={'assets/img/techStack/c++.svg'} text={'c/c++'} alt={"C/C++"}/>
          <TechStackBox src={'assets/img/techStack/nodejs.svg'} text={'node.js'} alt={"Node.js"}/>
          <TechStackBox src={'assets/img/techStack/webpack.svg'} text={'webpack'} alt={"Webpack"}/>
          <TechStackBox src={'assets/img/techStack/esbuild.svg'} text={'esbuild'} alt={"Esbuild"}/>
          <TechStackBox src={'assets/img/techStack/arduino.svg'} text={'embedded'} alt={"Embedded"}/>
          <TechStackBox src={'assets/img/techStack/adobe-photoshop.svg'} text={'photoshop'} alt={"Photoshop"}/>
          <TechStackBox src={'assets/img/techStack/sass.svg'} text={'SCSS'} alt={"SCSS"}/>
          <TechStackBox src={'assets/img/techStack/express-js.svg'} text={'express.js'} alt={"Express.js"}/>
        </ul>
        <h3 className="tech-title">During learning/want to learn</h3>
        <ul className={'learn-grid'}>
          <TechStackBox src={'assets/img/techStack/tailwind-css.svg'} text={'tailwindCSS'} alt={"TailwindCSS"}/>
          <TechStackBox src={'assets/img/techStack/next-js.svg'} text={'next.js'} alt={"Next.js"}/>
          <TechStackBox src={'assets/img/techStack/tensorflow.svg'} text={'tensorflow'} alt={"Tensorflow"}/>
          <TechStackBox src={'assets/img/techStack/aws.svg'} text={'aws'} alt={"AWS"}/>
        </ul>
      </div>
    );
  }
}
